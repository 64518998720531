<template>
    <div>
        <b-container>
            <section class="categories">
                <div class="sector mb-0">
                    <h2 class="main-title">Categories</h2>
                    <p class="main-subtitle">Browse companies by service</p>
                    <b-row>
                        <b-col cols="12" sm="4" lg="2">
                            <router-link :to="{ name: 'agencies', query: { service: 0 } }" class="categories__link">
                                <div class="categories__item">
                                    <v-icon name="ad" scale="5" />
                                    <h3 class="categories__title">Advertising</h3>
                                </div>
                            </router-link>
                        </b-col>
                        <b-col cols="12" sm="4" lg="2">
                            <router-link :to="{ name: 'agencies', query: { service: 1 } }" class="categories__link">
                                <div class="categories__item">
                                    <v-icon name="bullhorn" scale="5" />
                                    <h3 class="categories__title">Digital Marketing</h3>
                                </div>
                            </router-link>
                        </b-col>
                        <b-col cols="12" sm="4" lg="2">
                            <router-link :to="{ name: 'agencies', query: { service: 2 } }" class="categories__link">
                                <div class="categories__item">
                                    <v-icon name="copyright" scale="5" />
                                    <h3 class="categories__title">Branding</h3>
                                </div>
                            </router-link>
                        </b-col>
                        <b-col cols="12" sm="4" lg="2">
                            <router-link :to="{ name: 'agencies', query: { service: 3 } }" class="categories__link">
                                <div class="categories__item">
                                    <v-icon name="hashtag" scale="5" />
                                    <h3 class="categories__title">Social Media Marketing</h3>
                                </div>
                            </router-link>
                        </b-col>
                        <b-col cols="12" sm="4" lg="2">
                            <router-link :to="{ name: 'agencies', query: { service: 4 } }" class="categories__link">
                                <div class="categories__item">
                                    <v-icon name="desktop" scale="5" />
                                    <h3 class="categories__title">Web Development</h3>
                                </div>
                            </router-link>
                        </b-col>
                        <b-col cols="12" sm="4" lg="2">
                            <router-link :to="{ name: 'agencies', query: { service: 5 } }" class="categories__link">
                                <div class="categories__item">
                                    <v-icon name="mobile-alt" scale="5" />
                                    <h3 class="categories__title">Mobile App Development</h3>
                                </div>
                            </router-link>
                        </b-col>
                        <b-col cols="12" sm="4" lg="2">
                            <router-link :to="{ name: 'agencies', query: { service: 6 } }" class="categories__link">
                                <div class="categories__item">
                                    <v-icon name="credit-card" scale="5" />
                                    <h3 class="categories__title">eCommerce</h3>
                                </div>
                            </router-link>
                        </b-col>
                        <b-col cols="12" sm="4" lg="2">
                            <router-link :to="{ name: 'agencies', query: { service: 7 } }" class="categories__link">
                                <div class="categories__item">
                                    <v-icon name="database" scale="5" />
                                    <h3 class="categories__title">Big Data</h3>
                                </div>
                            </router-link>
                        </b-col>
                        <b-col cols="12" sm="4" lg="2">
                            <router-link :to="{ name: 'agencies', query: { service: 8 } }" class="categories__link">
                                <div class="categories__item">
                                    <v-icon name="at" scale="5" />
                                    <h3 class="categories__title">E-mail Marketing</h3>
                                </div>
                            </router-link>
                        </b-col>
                        <b-col cols="12" sm="4" lg="2">
                            <router-link :to="{ name: 'agencies', query: { service: 9 } }" class="categories__link">
                                <div class="categories__item">
                                    <v-icon name="calendar-check" scale="5" />
                                    <h3 class="categories__title">Event Marketing</h3>
                                </div>
                            </router-link>
                        </b-col>
                        <b-col cols="12" sm="4" lg="2">
                            <router-link :to="{ name: 'agencies', query: { service: 11 } }" class="categories__link">
                                <div class="categories__item">
                                    <v-icon name="search" scale="5" />
                                    <h3 class="categories__title">SEO</h3>
                                </div>
                            </router-link>
                        </b-col>
                        <b-col cols="12" sm="4" lg="2">
                            <router-link :to="{ name: 'agencies', query: { service: 12 } }" class="categories__link">
                                <div class="categories__item">
                                    <v-icon name="paint-brush" scale="5" />
                                    <h3 class="categories__title">Graphic Design</h3>
                                </div>
                            </router-link>
                        </b-col>
                    </b-row>
                    <b-row class="justify-content-center">
                        <router-link to="/agencies" class="details-button details-button--red"><simple-line-icons icon="arrowRight" size="small" color="#504e70" />View more</router-link>
                    </b-row>
                </div>
            </section>
        </b-container>
    </div>
</template>

<script>
import 'vue-awesome/icons/ad'
import 'vue-awesome/icons/bullhorn'
import 'vue-awesome/icons/copyright'
import 'vue-awesome/icons/hashtag'
import 'vue-awesome/icons/desktop'
import 'vue-awesome/icons/mobile-alt'
import 'vue-awesome/icons/credit-card'
import 'vue-awesome/icons/database'
import 'vue-awesome/icons/at'
import 'vue-awesome/icons/calendar-check'
import 'vue-awesome/icons/search'
import 'vue-awesome/icons/paint-brush'
import Icon from 'vue-awesome/components/Icon'
import SimpleLineIcons from 'vue-simple-line'
export default {
  components: {
    'v-icon': Icon,
    SimpleLineIcons
  }
}
</script>
